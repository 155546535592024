import React from "react"

export default function Utterances() {
  const ref = React.useRef(null)

  React.useEffect(() => {
    if (!ref.current) {
      return
    }

    const script = document.createElement("script")

    script.setAttribute("issue-term", "pathname")
    script.setAttribute("repo", "brapifra/brais.dev-comments")
    script.setAttribute("theme", "github-light")

    script.crossOrigin = "anonymous"
    script.async = true
    script.src = "https://utteranc.es/client.js"

    ref.current.innerHTML = ""
    ref.current.appendChild(script)
  }, [ref.current])

  return <div ref={ref}></div>
}
